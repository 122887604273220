import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Scenario from './components/Scenario';
import Grille from './components/Grille';
import Grille_video from './components/Grille_video';
import Scenario_video from './components/Scenario_video';
import AgeVerification from './AgeVerification';
import WakeLockComponent from './components/WakeLockComponent';

import '@fortawesome/fontawesome-free/css/all.min.css';

import './global.css'

const App: React.FC = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const handleVerification = (verified: boolean) => {
    setIsVerified(verified);
  };

  return (
    <Router>
      <WakeLockComponent />
      <style>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        body {
          margin: 0;
          padding: 0;
        }
      `}</style>
      {isVerified ? (
        <>
          <Header />
          <div>
            <Routes>
              <Route path="/" element={<Scenario />} />
              <Route path="/Scenario_video" element={<Scenario_video />} />
              <Route path="/Grille" element={<Grille />} />
              <Route path="/Grille_video" element={<Grille_video />} />
            </Routes>
          </div>
          <Footer />
        </>
      ) : (
        <AgeVerification onVerify={handleVerification} />
      )}
    </Router>
  );
};

export default App;
