import mqtt, { MqttClient, IClientOptions, MqttProtocol } from 'mqtt';

const options: IClientOptions = {
    protocol: 'wss' as MqttProtocol,
    host: 'foodybrain.com',
    port: 8083,
    rejectUnauthorized: false, // Accepte les certificats auto-signés
};

const client: MqttClient = mqtt.connect(options);

client.on('connect', () => {
    console.log('Connected to MQTT broker');
});

client.on('error', (err) => {
    console.error('MQTT connection error:', err);
});

export interface Feedback {
    id?: number;
    rating: number;
    ip?: string;
    timestamp?: string;
}

export const addFeedback = (type: string, rating: number, ip: string): void => {
    const feedback: Feedback = { rating, ip, timestamp: new Date().toISOString() };
    client.publish(`feedbacks/${type}`, JSON.stringify(feedback), { qos: 1 }, (error: any) => {
        if (error) {
            console.error('Failed to send feedback:', error);
        } else {
            console.log('Feedback sent successfully');
        }
    });
};