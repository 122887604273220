import React, { useState, useEffect } from 'react';
import './Grille_video_style.css'; // Importez la feuille de style globale
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Height } from '@mui/icons-material';

const ContainerStyle = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  background-color: black;

  @media (max-width: 56.25em) {
    align-items: flex-start;
  }
`;

const Grille_video: React.FC = () => {
  const location = useLocation();
  const { paths } = location.state as { paths: string[] };
  const [videos, setVideos] = useState(paths);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.matchMedia('(max-width: 56.25em)').matches);
  const [isIphone, setIsIphone] = useState<boolean>(/iPhone|iPad|iPod/i.test(navigator.userAgent));
  const navigate = useNavigate();
 
  const handleVideoClick = (index: number, event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedVideoIndex(index);
  };

  const exitFullScreen = () => {  
    if (document.exitFullscreen) {
      document.exitFullscreen().catch(err => console.log(err));
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen().catch(err => console.log(err));
    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
      document.webkitExitFullscreen().catch(err => console.log(err));
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen().catch(err => console.log(err));
    } else {
      console.log("Exit fullscreen API is not supported.");
    }

    // Reverting the viewport settings for iOS devices
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector("meta[name=viewport]") as HTMLMetaElement;
      if (viewportMeta) {
          viewportMeta.content = "width=device-width, initial-scale=1.0, user-scalable=yes";
      }
    }
  };

  const handleBackClick = () => {
    document.body.style.overflow = 'auto';
    navigate('/Grille');
    exitFullScreen();
  };

  useEffect(() => {
    const lockOrientation = async () => {
      // eslint-disable-next-line no-restricted-globals
      if (screen.orientation && screen.orientation.lock) {
        try {
          // eslint-disable-next-line no-restricted-globals
          await screen.orientation.lock('landscape');
        } catch (error) {
          console.error('Error locking screen orientation:', error);
        }
      }
    };

    lockOrientation();

    return () => {
      // eslint-disable-next-line no-restricted-globals
      if (screen.orientation && screen.orientation.unlock) {
        // eslint-disable-next-line no-restricted-globals
        screen.orientation.unlock();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 56.25em)').matches);
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        if(window.innerHeight > window.innerWidth){
          alert("Veuillez faire pivoter votre appareil en mode paysage pour une meilleure expérience.");
        }
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  document.body.style.overflow = 'hidden'; // Disable scroll

  return (
    <ContainerStyle>
      <button onClick={handleBackClick} style={styles.backButton}>
        Retour
      </button>
      {videos.map((src, index) => (
        <video
          key={index}
          style={
            selectedVideoIndex === index
              ? isIphone ? styles.videoMainIphone : styles.videoMain
              : styles.videoSecondaire
          }
          onClick={(event) => handleVideoClick(index, event)}
          controls={isMobile ? selectedVideoIndex === index : true}
          autoPlay
          loop
          muted={selectedVideoIndex !== index}
          className="custom-video"
          preload="auto"
          playsInline 
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}
    </ContainerStyle>
  );
};

const styles: { [key: string]: React.CSSProperties | any } = {
  backButton: {
    position: 'absolute' as const,
    top: '10px',
    left: '10px',
    padding: '12px 22px',
    backgroundColor: '#EA6666',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    zIndex: '1001',
  },
  videoMain: {
    backgroundColor: 'black',
    position: 'absolute',
    width: '100%', // Mise à jour pour ne pas dépasser l'écran
    objectFit: 'cover',
    opacity: '1',
    zIndex: '1000',
  },
  videoMainIphone: {
    backgroundColor: 'black',
    position: 'absolute',
    width: '70vw', // Ajustement pour iPhone
    height: 'auto', // Ajustement pour iPhone
    marginLeft: '15vw',
    marginRight: '15vw',
    objectFit: 'cover',
    opacity: '1',
    zIndex: '1000',
  },
  videoSecondaire: {
    width: '13%',
    margin: '10px 0',
    opacity: '0.7',
    transition: 'opacity 0.3s',
    cursor: 'pointer',
    zIndex: '1001',
  },
};

export default Grille_video;