import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.section}>
          <h2 style={styles.heading}>Company</h2>
          <ul style={styles.list}>
            <li><a href="#about" style={styles.link}>About Us</a></li>
            <li><a href="#careers" style={styles.link}>Careers</a></li>
            <li><a href="mailto:contact@xclusiveporn.com" style={styles.link}>contact@xclusiveporn.com</a></li>
          </ul>
        </div>
        <div style={styles.section}>
          <h2 style={styles.heading}>Help</h2>
          <ul style={styles.list}>
            <li><a href="mailto:support@xclusiveporn.com" style={styles.link}>support@xclusiveporn.com</a></li>
            <li><a href="#faq" style={styles.link}>FAQ</a></li>
            <li><a href="#terms" style={styles.link}>Terms & Conditions</a></li>
          </ul>
        </div>
        <div style={styles.section}>
          <h2 style={styles.heading}>Suivez nous</h2>
          <ul style={styles.list}>
            <li><a href="#facebook" style={styles.link}>Facebook</a></li>
            <li><a href="#twitter" style={styles.link}>Twitter</a></li>
            <li><a href="#instagram" style={styles.link}>Instagram</a></li>
          </ul>
        </div>
      </div>
      <div style={styles.bottom}>
        <p style={styles.text}>©2024 XclusivePorn. All rights reserved. Version 1.0 du 15/08</p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#272727',
    color: '#ffffff',
    padding: '20px 0',
  } as React.CSSProperties,
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap', // Permet aux sections de passer à la ligne si nécessaire
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
  } as React.CSSProperties,
  section: {
    flex: 1,
    margin: '0 10px',
  } as React.CSSProperties,
  heading: {
    color: '#EA6666',
    fontSize: '18px',
    marginBottom: '10px',
  } as React.CSSProperties,
  list: {
    listStyleType: 'none',
    padding: 0,
  } as React.CSSProperties,
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    marginBottom: '5px',
    display: 'block',
  } as React.CSSProperties,
  bottom: {
    textAlign: 'center' as 'center',
    marginTop: '20px',
    borderTop: '1px solid #444',
    paddingTop: '10px',
  } as React.CSSProperties,
  text: {
    color: '#ffffff',
    fontSize: '14px',
  } as React.CSSProperties,
};

export default Footer;