import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const SiteHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: space-between;
  background-color: #272727;
  padding: 10px 20px;
`;

const Logo = styled.div`
  grid-row: 1 / 3;
  height: 150px;
  width: auto;
  display: flex;

 @media (max-width: 56.25em) {
      height: 75px;
    }
`;

const TopRow = styled.div`
  grid-column: 2;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const SearchBar = styled.div`
  width: 50%;
  display: flex;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 5px;
  border: none;
  border-radius: 3px;
`;

const SearchButton = styled.button`
  background-color: #555;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 3px;
  color: #EA6666;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }
`;

const BottomRow = styled.div`
  grid-column: 2;
  margin-top: 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center; 
`;

const Links = styled.div`
  display: flex;

  span {
    margin-left: 30px;
    color: #EA6666;
    text-decoration: none;
    padding: 5px 10px;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: #444;
      border-radius: 3px;
      text-decoration: underline;
    }

    @media (max-width: 56.25em) {
      margin-left: 10px;
      padding: 3px 5px;
      font-size: 0.9em;
    }
  }
`;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <SiteHeader>
      <Logo>
        <img id="logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
      </Logo>
      <TopRow>
        <SearchBar>
          <p></p>
          <SearchInput type="text" placeholder="Rechercher..." />
          <SearchButton type="submit">
            <i className="fas fa-search"></i>
          </SearchButton>
        </SearchBar>
      </TopRow>
      <BottomRow>
        <Links>
          <span
            onClick={() => navigate('/')}
            className={location.pathname === '/' ? 'active' : ''}
          >
            Scenario
          </span>
          <span
            onClick={() => navigate('/Grille')}
            className={location.pathname === '/Grille' ? 'active' : ''}
          >
            Grille
          </span>
        </Links>
      </BottomRow>
    </SiteHeader>
  );
};

export default Header;
