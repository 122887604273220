import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faBookmark, faEye, faPlay, faPause, faVolumeMute, faVolumeUp, faExpand, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import zIndex from '@mui/material/styles/zIndex';

interface Comment {
  text: string;
  time: string;
}

interface Chapter {
  id: number;
  title: string;
  start: number;
  end: number;
}

interface Page4State {
  videoList: Chapter[];
  src: string;
  src_minipreview: string;
  title: string;
}

const MiniPreviewVideo: React.FC<{ src: string; startTime: number }> = ({ src, startTime }) => {
  const previewRef = useRef<HTMLVideoElement>(null);
  const [state, setState] = useState({
    loading: true,
    dimensions: { width: 150, height: 84 },
  });

  useEffect(() => {
    const previewVideo = previewRef.current;
    if (previewVideo) {
      const handleLoadedData = () => {
        setState(prevState => ({ ...prevState, loading: false })); // Vidéo chargée
        previewVideo.currentTime = startTime;
        previewVideo.play().catch(error => console.error('Error playing video:', error));
      };

      const handleTimeUpdate = () => {
        if (previewVideo.currentTime >= startTime + 3) {
          previewVideo.currentTime = startTime;
        }
      };

      const handleError = () => {
        setState(prevState => ({ ...prevState, loading: true }));
        console.error('Error loading video');
      };

      previewVideo.addEventListener('loadeddata', handleLoadedData);
      previewVideo.addEventListener('timeupdate', handleTimeUpdate);
      previewVideo.addEventListener('error', handleError);

      return () => {
        previewVideo.removeEventListener('loadeddata', handleLoadedData);
        previewVideo.removeEventListener('timeupdate', handleTimeUpdate);
        previewVideo.removeEventListener('error', handleError);
      };
    }
  }, [startTime]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 56.25em)');
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setState(prevState => ({ ...prevState, dimensions: { width: 75, height: 42 } })); // Dimensions pour les petits écrans
      } else {
        setState(prevState => ({ ...prevState, dimensions: { width: 150, height: 84 } })); // Dimensions pour les grands écrans
      }
    };

    // Vérifiez initialement
    if (mediaQuery.matches) {
      setState(prevState => ({ ...prevState, dimensions: { width: 75, height: 42 } }));
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const handleVideoClick = () => {
    const previewVideo = previewRef.current;
    if (previewVideo) {
      previewVideo.play().catch(error => console.error('Error playing video:', error));
    }
  };

  return (
    <div 
      style={{ position: 'relative', width: `${state.dimensions.width}px`, height: `${state.dimensions.height}px` }}
      onClick={handleVideoClick}
    >
      {state.loading && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }}>Loading...</div>}
      <video 
        ref={previewRef} 
        width={state.dimensions.width} 
        height={state.dimensions.height} 
        muted 
        preload="metadata" 
        autoPlay
        playsInline // Ajouter playsInline pour iOS
        style={{ display: state.loading ? 'none' : 'block' }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};


const Introduction = styled.p`
  color: white;
  margin-top: 1px;
  margin-bottom: 5px;
  text-align: center;

  @media (max-width: 56.25em) {
    font-size: 0.9em;
    margin-left: 20%;
    text-align: end;
  }
`;

const VideoPlayer = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 600px;
  height: auto;
  border: 1px solid #EA6666;

  @media (max-width: 56.25em) {
    width: 100%;
    max-width: 100%;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;

  @media (max-width: 56.25em) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const ActiveVideoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 200px;

  @media (max-width: 56.25em) {
    width: 100%;
    align-items: center;
    gap: 5px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

const ActiveVideoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 56.25em) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

const SectionTitle = styled.h3<{ isFullScreen: boolean }>`
  margin-bottom: 5px;
  font-size: 18px;
  color: ${(props) => (props.isFullScreen ? 'black' : '#EA6666')};
  padding-left: 20px; 

  @media (max-width: 56.25em) {
    font-size: 0.9em;
    width: 100%;
    text-align: center;
    padding-left: 0px; 
  }
`;

const ActiveVideoItem = styled.div<{ isFullScreen: boolean }>`
  background-color: ${(props) => (props.isFullScreen ? 'black' : '#EA6666')};
  color: ${(props) => (props.isFullScreen ? 'black' : 'white')};
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;

  @media (max-width: 56.25em) {
    font-size: 0.7em;
    padding: 5px;
    margin-left: 0;
  }
`;

const VideoInteractions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin-bottom: 10px;

  @media (max-width: 56.25em) {
    width: 80%;
    font-size: 0.9em;
  }
`;

const CommentsSection = styled.div`
  marginTop: 10px;
  width: 600px;

  @media (max-width: 56.25em) {
    width: 80%;
  }
`;

const NextChapterButtonContainer = styled.div`
  position: absolute;
  bottom: 70px;
  right: 9%;
  z-index: 1001;

  @media (max-width: 56.25em) {
    bottom: 40px;
    right: 10px;
    font-size: 0.7em;
  }
`;

const PreviousChapterButtonContainer = styled.div`
  position: absolute;
  bottom: 70px;
  left: 9%;
  z-index: 1001;

  @media (max-width: 56.25em) {
    bottom: 40px;
    left: 10px;
    font-size: 0.8em;
  }
`;

const NextChapterButton = styled.button`
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #EA6666;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  
  @media (max-width: 56.25em) {
    font-size: 0.8em;
    padding: 3px;
    gap: 2px;
  }
`;

const PreviousChapterButton = styled.button`
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #EA6666;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (max-width: 56.25em) {
    font-size: 0.7em;
    padding: 3px;
    gap: 2px;
  }
`;

const CustomControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;

  @media (max-width: 56.25em) {
    padding: 2px;
    bottom : 0px;
    left: 50%;
  }
`;

const IconButtonVideo = styled.button`
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: #EA6666;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;

  @media (max-width: 56.25em) {
    font-size: 15px;
    padding: 4px;
  }
`;

const VolumeControl = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 56.25em) {
    justify-content: center;
    margin: 5px 0;
  }
`;

const VolumeSlider = styled.input`
  margin-left: 10px;
  width: 100px;

  @media (max-width: 56.25em) {
    margin-left: 2px;
    width: 40px;
  }
`;

const VideoItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px; /* Correction de borderRadius en border-radius */
  color: white;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 56.25em) {
    padding: 5px;
    font-size: 0.7em;
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: #444;
  border-radius: 0 0 5px 5px; /* Adjusted for proper rounded corners at the bottom */
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #EA6666;
  transition: width 0.2s;
  width: ${(props) => props.progress}%;
`;

const Scenario_video: React.FC = () => {
  const location = useLocation();
  const { videoList, src, src_minipreview, title } = location.state as Page4State;
  const videoRef = useRef<HTMLVideoElement>(null);

  const [firstTime, setFirstTime] = useState(true);
  const [likes, setLikes] = useState(0);
  const [saved, setSaved] = useState(false);
  const [views, setViews] = useState(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [activeChapters, setActiveChapters] = useState<Chapter[]>([]);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [volume, setVolume] = useState(100);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const navigate = useNavigate();
  let mouseMoveTimeout: NodeJS.Timeout;

  if (firstTime) {
    window.scrollTo(0, 0);
    setFirstTime(false);
  }

  //A voir : Reset à 0 quand on change de chapitre
  /*
  useEffect(() => {
    if (activeChapters.length > 0 && videoRef.current) {
      setCurrentSegment(0);
      videoRef.current.currentTime = activeChapters[0].start;
    }
  }, [activeChapters]);*/

  const deactivateAllChapters = () => {
    setActiveChapters([]);
  };

  const addComment = (comment: string) => {
    const currentDateTime = new Date().toLocaleTimeString();
    setComments([...comments, { text: comment, time: currentDateTime }]);
  };

  const handlePlayPause = () => {
    if (activeChapters.length === 0) {
      alert('Veuillez sélectionner au moins un chapitre à jouer');
      return;
    }
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseInt(event.target.value, 10);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume / 100;
      setIsMuted(newVolume === 0);
    }
  };

  const enterFullScreen = (param : HTMLElement) => {
    const element = param;

    if (element.requestFullscreen) {
        element.requestFullscreen().catch(err => console.log(err));
    } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen().catch(err => console.log(err));
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
        element.webkitRequestFullscreen().catch(err => console.log(err));
    } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen().catch(err => console.log(err));
    } else {
        console.log("Fullscreen API is not supported.");
    }

    // Forcing full screen on iOS devices
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector("meta[name=viewport]") as HTMLMetaElement;
      if (viewportMeta) {
          viewportMeta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      }
      window.scrollTo(0, 1);
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch(err => console.log(err));
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen().catch(err => console.log(err));
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen().catch(err => console.log(err));
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen().catch(err => console.log(err));
    } else {
      console.log("Exit fullscreen API is not supported.");
    }

    // Reverting the viewport settings for iOS devices
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector("meta[name=viewport]") as HTMLMetaElement;
      if (viewportMeta) {
          viewportMeta.content = "width=device-width, initial-scale=1.0, user-scalable=yes";
      }
    }
  };

  useEffect(() => {
    const lockOrientation = async () => {
      // eslint-disable-next-line no-restricted-globals
      if (screen.orientation && screen.orientation.lock) {
        try {
          // eslint-disable-next-line no-restricted-globals
          await screen.orientation.lock('landscape');
        } catch (error) {
          console.error('Error locking screen orientation:', error);
        }
      }
    };

    if(isFullScreen){ 
      lockOrientation();
    }

    return () => {
      // eslint-disable-next-line no-restricted-globals
      if (screen.orientation && screen.orientation.unlock &&!isFullScreen) {
        // eslint-disable-next-line no-restricted-globals
        screen.orientation.unlock();
      }
    };
  }, [isFullScreen]);

  const handleFullScreen = () => {
    if (videoRef.current) {
      const videoContainer = videoRef.current.parentElement;
      const backButton = document.getElementById('backButton');

      if (videoContainer) {
        if (videoContainer.style.position === 'fixed') {
          videoContainer.style.position = 'relative';
          videoContainer.style.top = 'auto';
          videoContainer.style.left = 'auto';
          videoContainer.style.width = '600px';
          videoContainer.style.height = 'auto';
          videoContainer.style.backgroundColor = '';
          document.body.style.overflow = 'auto';

          if (backButton) {
            backButton.style.position = 'relative';
          }

          setIsFullScreen(!isFullScreen);
          exitFullScreen();
        } else {
          videoContainer.style.position = 'fixed';
          videoContainer.style.top = '0';
          videoContainer.style.left = '0';
          videoContainer.style.width = '100%';
          videoContainer.style.height = '100%';
          videoContainer.style.zIndex = '1000';
          videoContainer.style.backgroundColor = 'black';
          document.body.style.overflow = 'hidden';

          if (backButton) {
            backButton.style.position = 'fixed';
          }

          setIsFullScreen(!isFullScreen);
          enterFullScreen(videoContainer);
        }
      }
    }
  };

  //A voir : Passe au prochian chapitre (a utiliser si on enlève un chapitre actif qui correspond au chapitre joué)
  const skipNextChapter = () => {
    if (videoRef.current) {
      if (currentSegment < activeChapters.length - 1) {
        setCurrentSegment(currentSegment + 1);
        videoRef.current.currentTime = activeChapters[currentSegment + 1].start;
      } else {
        deactivateAllChapters();
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const skipPreviousChapter = () => {
    if (videoRef.current) {
      if (currentSegment > 0) {
        setCurrentSegment(currentSegment - 1);
        videoRef.current.currentTime = activeChapters[currentSegment - 1].start;
      } else {
        videoRef.current.currentTime = activeChapters[0].start;
      }
    }
  };

  //A voir : Comment sont gérer les times codes (fonction la plus improtante)
  const handleTimecodeSelect = (chapter: Chapter) => {
    const video = videoRef.current;
    let isActive = false;
    
    const updatedChapters = activeChapters.filter(ch => {
      if (ch.start === chapter.start) {
        isActive = true;
        return false;
      }
      return true;
    });
    
    if (!isActive) {
      updatedChapters.push(chapter);
    }

    // Vérifiez si activeChapters était vide et devient non vide
    const wasEmpty = activeChapters.length === 0;

    setActiveChapters(updatedChapters);
  
    if(video) {
      const currentTime = video.currentTime;
      const currentChapterIndex = updatedChapters.findIndex(ch => currentTime >= ch.start && currentTime < ch.end);
  
      if (wasEmpty && updatedChapters.length === 1) {
        setCurrentSegment(0);
        video.currentTime = updatedChapters[0].start;
      } else if (currentChapterIndex !== -1 && currentChapterIndex !== currentSegment) {
        setCurrentSegment(currentChapterIndex);
      } else if (isActive && currentChapterIndex === -1) {
        if (updatedChapters.length > 0) {
          // Ensure the segment update if the current chapter was removed
          const nextSegmentIndex = updatedChapters.findIndex(ch => ch.start > currentTime);
          if (nextSegmentIndex !== -1) {
            setCurrentSegment(nextSegmentIndex);
            video.currentTime = updatedChapters[nextSegmentIndex].start;
          } else {
            setCurrentSegment(0);
            video.currentTime = updatedChapters[0].start;
          }
        } else {
          skipNextChapter();
        }
      }
    }
  };

  //A voir : Je pense un doublon avec la fonction au dessus
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (
        currentSegment < activeChapters.length &&
        video.currentTime >= activeChapters[currentSegment].end
      ) {
        if (currentSegment < activeChapters.length - 1) {
          setCurrentSegment(currentSegment + 1);
          video.currentTime = activeChapters[currentSegment + 1].start;
        } else {
          video.currentTime = 0;
          video.pause();
          setIsPlaying(false);
          deactivateAllChapters();
        }
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [activeChapters, currentSegment]);

  const handleMouseMove = () => {
      setControlsVisible(true);

      clearTimeout(mouseMoveTimeout);
      mouseMoveTimeout =setTimeout(() => {
        setControlsVisible(false);
    }, 4000);
  };

  const handleBackClick = () => {
    document.body.style.overflow = 'auto';
    navigate('/');
  };

  const handleSkipForward = () => {
    if (videoRef.current) {
      let newTime = videoRef.current.currentTime + 10;
      if (newTime > activeChapters[currentSegment].end) {
        if (currentSegment < activeChapters.length - 1) {
          newTime = activeChapters[currentSegment + 1].start + (newTime - activeChapters[currentSegment].end);
          setCurrentSegment(currentSegment + 1);
        } else {
          newTime = activeChapters[0].start;
          setCurrentSegment(0);
        }
      }
      videoRef.current.currentTime = newTime;
    }
  };

  const handleSkipBackward = () => {
    if (videoRef.current) {
      let newTime = videoRef.current.currentTime - 10;
      if (newTime < activeChapters[currentSegment].start) {
        if (currentSegment > 0) {
          newTime = activeChapters[currentSegment - 1].end - (activeChapters[currentSegment].start - newTime);
          setCurrentSegment(currentSegment - 1);
        } else {
          newTime = activeChapters[0].start;
          setCurrentSegment(0);
        }
      }
      videoRef.current.currentTime = newTime;
    }
  };

  //A voir : Faut rendre çaplus fluide
  const calculateProgress = () => {
    if (videoRef.current && activeChapters.length > 0) {
      const totalDuration = activeChapters.reduce((total, chapter) => total + (chapter.end - chapter.start), 0);
      const elapsedTime = activeChapters.slice(0, currentSegment).reduce((total, chapter) => total + (chapter.end - chapter.start), 0) + (videoRef.current.currentTime - activeChapters[currentSegment].start);
      return (elapsedTime / totalDuration) * 100;
    }
    return 0;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreen) {
        handleFullScreen();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreen]);

  const shouldShowNextChapterButton = () => {
    return currentSegment < activeChapters.length - 1;
  };

  const shouldShowPreviousChapterButton = () => {
    return currentSegment > 0;
  };

  return (
    <div style={isFullScreen ? styles.top_fullscreen : styles.top}>
      <button id="backButton" onClick={handleBackClick} style={styles.backButton}>
        Retour
      </button>
      <Introduction>Choisissez les chapitres dans l'ordre dans lequel vous voulez les voir et produisez une vidéo unique</Introduction>

      <div style={isFullScreen ? styles.appContainer_fullscreen : styles.appContainer}>
        <div style={styles.inactiveChaptersContainer}>
        <SectionTitle isFullScreen={isFullScreen}>Chapitres à choisir :</SectionTitle>
          <div style={styles.videoList}>
            {videoList.filter(video => !activeChapters.some(chapter => chapter.id === video.id)).map(video => (
              <div key={video.id} style={styles.chapterContainer}>
                <MiniPreviewVideo src={src_minipreview} startTime={Math.max(video.start, video.start + (video.end - video.start) / 2 - 1.5)}/>
                <VideoItem
                  onClick={() => handleTimecodeSelect(video)}
                >
                  {video.title}
                </VideoItem>
              </div>
            ))}
          </div>
        </div>

        <VideoContainer>
          <VideoPlayer id="videoPlayer">
            <video ref={videoRef} style={styles.videoElement} preload="metadata" playsInline  onMouseMove={handleMouseMove}>
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {controlsVisible && (
              <div>
                {shouldShowNextChapterButton() && (
                  <NextChapterButtonContainer>
                    <NextChapterButton onClick={skipNextChapter}>
                      <FontAwesomeIcon icon={faForward} />
                      Next Chapter
                    </NextChapterButton>
                  </NextChapterButtonContainer>
                )}
                {shouldShowPreviousChapterButton() && (
                  <PreviousChapterButtonContainer>
                    <PreviousChapterButton onClick={skipPreviousChapter}>
                      <FontAwesomeIcon icon={faBackward} />
                      Previous Chapter
                    </PreviousChapterButton>
                  </PreviousChapterButtonContainer>
                )}
                <CustomControls>
                  <IconButtonVideo onClick={handlePlayPause}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                  </IconButtonVideo>
                  <IconButtonVideo onClick={handleSkipBackward}>
                    -10s
                  </IconButtonVideo>
                  <IconButtonVideo onClick={handleSkipForward}>
                    +10s
                  </IconButtonVideo>
                  <VolumeControl>
                    <IconButtonVideo onClick={handleMute}>
                      <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                    </IconButtonVideo>
                    <VolumeSlider
                      type="range"
                      min="0"
                      max="100"
                      value={isMuted ? 0 : volume}
                      onChange={handleVolumeChange}
                    />
                  </VolumeControl>
                  <IconButtonVideo onClick={handleFullScreen}>
                    <FontAwesomeIcon icon={faExpand} />
                  </IconButtonVideo>
                </CustomControls>
                <ProgressContainer>
                  <ProgressBar progress={calculateProgress()} />
                </ProgressContainer>
              </div>
            )}
          </VideoPlayer>
          <ActiveVideoListWrapper>
          <SectionTitle isFullScreen={isFullScreen}>Chapitres choisis :</SectionTitle>
            <ActiveVideoList>
              {activeChapters.map(video => (
                <ActiveVideoItem
                  isFullScreen={isFullScreen}
                  key={video.id}
                  onClick={() => handleTimecodeSelect(video)}
                >
                  {video.title}
                </ActiveVideoItem>
              ))}
            </ActiveVideoList>
          </ActiveVideoListWrapper>
        </VideoContainer>

        <div style={styles.title}>
          <p>{title}</p>
        </div>

        <VideoInteractions>
          <button style={styles.iconButton} onClick={() => setLikes(likes + 1)}>
            <FontAwesomeIcon icon={faThumbsUp} /> Like ({likes})
          </button>
          <button style={styles.iconButton} onClick={() => setSaved(!saved)}>
            <FontAwesomeIcon icon={faBookmark} /> {saved ? 'Unsave' : 'Save'}
          </button>
          <p style={styles.views}>
            <FontAwesomeIcon icon={faEye} /> <span style={{ marginLeft: '5px' }}>Views: {views}</span>
          </p>
        </VideoInteractions>
        <CommentsSection>
          <h3 style={{ textAlign: 'left' }}>Commentaires</h3>
          <input
            type="text"
            placeholder="Ajoutez un commentaire"
            style={styles.commentInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addComment((e.target as HTMLInputElement).value);
                (e.target as HTMLInputElement).value = '';
              }
            }}
          />
          <ul style={styles.commentsList}>
            {comments.map((comment, index) => (
              <li key={index} style={styles.commentItem}>
                {comment.text}
                <span style={styles.commentTime}>{comment.time}</span>
              </li>
            ))}
          </ul>
        </CommentsSection>
      </div>
    </div>
  );
};

const styles = {
  top: {
    padding: '20px',
    backgroundColor: '#202020',
  },
  top_fullscreen: {
    color: 'black',
    backgroundColor: 'black',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    minHeight: '100vh',
    color: 'white',
  },
  appContainer_fullscreen: {
    color: 'black',
    backgroundColor: 'black',
  },
  title: {
    color: '#EA6666',
    marginBottom: '20px',
    fontSize : '15px',
  },
  backButton: {
    position: 'relative' as const,
    top: '0px',
    left: '0px',
    padding: '10px 20px',
    backgroundColor: '#EA6666',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    zIndex: '1001',
  },
  inactiveChaptersContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    marginBottom: '5px',
    width: '100%',
  },
  videoList: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
    flexWrap: 'wrap' as const, // Ajoutez flexWrap pour permettre le saut de ligne
    justifyContent: 'center', // Centrez les éléments
  },
  chapterContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
  },
  videoElement: {
    width: '100%',
    height: '100%',
    zIndex:'1000',
  },
  iconButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: 'white',
  },
  views: {
    display: 'flex',
    alignItems: 'center',
  },
  commentsList: {
    listStyleType: 'none' as const,
    padding: 0,
  },
  commentItem: {
    marginBottom: '5px',
    padding: '5px',
    border: '1px solid #202020',
    borderRadius: '5px',
    color: '#202020',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    boxSizing: 'border-box' as const,
  },
  commentTime: {
    fontSize: '0.8em',
    color: '#666',
    marginLeft: '10px',
  },
};

export default Scenario_video;
