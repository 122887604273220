// AgeVerification.tsx
import React from 'react';
import styled from '@emotion/styled';


const Box = styled.div`
  background-color: #272727;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  text-align: center;

  @media (max-width: 50em) {
    width: 80%;
    padding: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5em;
  width: 100%;

  @media (max-width: 50em) {
    gap: 1em;
  }
`;

const AgeVerification: React.FC<{ onVerify: (verified: boolean) => void }> = ({ onVerify }) => {
  const handleVerification = (isAdult: boolean) => {
    if (isAdult) {
      onVerify(true);
    } else {
      onVerify(false);
      window.close(); // Ferme la page si l'utilisateur clique sur -18 ans
    }
  };

  return (
    <div style={styles.container}>
      <Box>
        <h2 style={styles.title}>Age Verification</h2>
        <p style={styles.description}>
          This website contains age-restricted materials including nudity and explicit depictions of sexual activity. By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.
        </p>
        <ButtonContainer>
          <button style={styles.adultButton} onClick={() => handleVerification(true)}>I am 18 or older</button>
          <button style={styles.underageButton} onClick={() => handleVerification(false)}>I am under 18</button>
        </ButtonContainer>
      </Box>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#202020',
    color: 'white',
  },
  title: {
    marginBottom: '10px',
  },
  description: {
    marginBottom: '20px',
    fontSize: '14px',
  },
  adultButton: {
    backgroundColor: '#EA6666',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  underageButton: {
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default AgeVerification;
