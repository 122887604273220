import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { videoData_Scenario } from '../videos/videosData.js';
import { addFeedback } from '../services/feedbackService';
import StarRating from '../services/StarRating';
import { colors } from '@mui/material';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import styled from '@emotion/styled';

const VideoGrid = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;

  @media (max-width: 56.25em) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Title = styled.h1`
  color: #EA6666;

  @media (max-width: 56.25em) {
    font-size: 1.5em;
    padding: 3px;
  }
`;

const VideoTitle = styled.p`
  color: #EA6666;
  margin-top: 10px;
  text-align: center;
  margin-left: 7.5%;
  margin-right: 7.5%;

  @media (max-width: 56.25em) {
    margin-top: 1px;
    font-size: 0.9em;
  }
`;

const Introduction = styled.p`
    color: white;
    margin-top: 5px;

  @media (max-width: 56.25em) {
    margin-top: 1px;
    font-size: 0.9em;
    padding: 3px;
  }
`;

const UpButton = styled.button`
    position: fixed;
    bottom: 4%;
    right: 1%;
    font-size: 15px;
    background-color: rgba(234, 102, 102, 0.9);
    border: none;
    color: white;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;

    @media (max-width: 56.25em) {
    font-size: 10px;
    right: 3%;
  }
`;

const Scenario: React.FC = () => {
  // Feedback 
  const [rating, setRating] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [ip, setIp] = useState<string>('');

  useEffect(() => {
    // Fetch public IP address
    axios.get('https://api.ipify.org?format=json')
        .then(response => {
            setIp(response.data.ip);
        })
        .catch(error => {
            console.error('Failed to fetch IP address:', error);
        });
}, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Empêche le rafraîchissement de la page
    addFeedback("scenario", rating, ip);
    setRating(0);
    setSubmitted(true);
  };
  //End

  const shuffleArray = (array: any[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const [shuffledVideos, setShuffledVideos] = useState(() => shuffleArray(videoData_Scenario));

  const navigate = useNavigate();

  const handleVideoClick = (id: number) => {
    navigateToPage4(id);
  };

  const navigateToPage4 = (id: number) => {
    const selectedVideo = videoData_Scenario.find(video => video.id === id);
    if (selectedVideo) {
      navigate('/Scenario_video', {
        state: {
          videoList: selectedVideo.chapters,
          src: selectedVideo.src,
          src_minipreview: selectedVideo.src_minipreview,
          title: selectedVideo.title,
        }
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={styles.homepage}>
      <h2>Feedback de la feature</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <StarRating rating={rating} setRating={setRating} />
          </label>
        </div>
        <button style={styles.submitButton} type="submit">Envoyer</button>
      </form>
      {submitted && <p>Merci pour vos feedbacks ! Si vous avez 1min afin de répondre anonyment à un formulaire de feedback, c'est par <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2p0vmB-SBnrl4vpniO9Nj-IefFVWSWvrO7q4QmIb3krR1HQ/viewform" style={styles.link} target="_blank"
      rel="noopener noreferrer">ici</a></p>}
      <Title>Composition des scénarios</Title>
      <Introduction>Cliquez sur la vidéo qui vous fait le plus envie</Introduction>
      <VideoGrid>
        {shuffledVideos.map((video) => (
          <VideoPreview
            key={video.id}
            video={video}
            onVideoClick={() => handleVideoClick(video.id)}
          />
        ))}
      </VideoGrid>
      <UpButton onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </UpButton>
    </div>
  );
};

const VideoPreview: React.FC<{ video: { id: number; src: string; title: string; previews: string[] }; onVideoClick: () => void }> = ({ video, onVideoClick }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  const videoPreviewRef = useRef<HTMLDivElement | null>(null);
  const isMobile = window.matchMedia('(max-width: 56.25em)').matches;

  const handleMouseEnter = () => {
    if (!isMobile) {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsHovered(true);
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(false);
    setPreviewIndex(0);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isHovered) {
      interval = setInterval(() => {
        setPreviewIndex((prevIndex) => (prevIndex + 1) % video.previews.length);
      }, 1000); // Changer d'image toutes les secondes
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isHovered, video.previews.length]);

  useEffect(() => {
    if (isMobile && videoPreviewRef.current) {
      intersectionObserverRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.7) {
              hoverTimeoutRef.current = setTimeout(() => {
                setIsHovered(true);
              }, 3000); // 3 secondes de délai
            } else {
              if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
              }
              setIsHovered(false);
              setPreviewIndex(0);
            }
          });
        },
        {
          threshold: [0.7],
        }
      );

      intersectionObserverRef.current.observe(videoPreviewRef.current);

      return () => {
        if (intersectionObserverRef.current) {
          intersectionObserverRef.current.disconnect();
        }
      };
    }
  }, [isMobile]);

  return (
    <div
      ref={videoPreviewRef}
      style={styles.videoPreview}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onVideoClick}
    >
      <img
        src={isHovered ? video.previews[previewIndex] : `/thumbnails/scenario/${video.id}.jpg`}
        alt={video.title}
        style={styles.thumbnail}
      />
      <VideoTitle>{video.title}</VideoTitle>
    </div>
  );
};

const styles = {
  homepage: {
    textAlign: 'center' as const,
    backgroundColor: '#202020',
    color: 'white',
  },
  title: {
    color: '#EA6666',
  },
  loading: {
    color: '#EA6666',
    fontSize: '20px',
  },
  videoPreview: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
  },
  thumbnail: {
    width: '85%',
    height: 'auto',
    borderRadius: '8px',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#EA6666',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '5px',
  },
  link: {
    color: '#3D40FF',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
};

export default Scenario;
