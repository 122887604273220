import React, { useEffect, useRef } from 'react';

const WakeLockComponent: React.FC = () => {
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          wakeLockRef.current = await navigator.wakeLock.request('screen');
          console.log('Wake Lock activé');
        }
      } catch (err) {
        console.error('Échec de l\'activation du Wake Lock:', err);
      }
    };

    requestWakeLock();

    return () => {
      if (wakeLockRef.current) {
        wakeLockRef.current.release().then(() => {
          console.log('Wake Lock désactivé');
        });
      }
    };
  }, []);

  return null; // Ne retourne rien à afficher
};

export default WakeLockComponent;
