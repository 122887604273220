import React, { useState } from 'react';

interface StarRatingProps {
    rating: number;
    setRating: (rating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, setRating }) => {
    const [hover, setHover] = useState<number>(0);

    const handleClick = (index: number) => {
        setRating(index);
    };

    const handleMouseEnter = (index: number) => {
        setHover(index);
    };

    const handleMouseLeave = () => {
        setHover(0);
    };

    const starElements = [];
    for (let i = 1; i <= 5; i++) {
        starElements.push(
            <span key={i}
                style={{ color: i <= Math.ceil(hover || rating) ? 'gold' : 'lightgray', cursor: 'pointer' }}
                onClick={() => handleClick(i)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
            >
                {i <= (hover || rating) ? '★' : '☆'}
            </span>
        );
    }

    return <div>Note : {starElements}</div>;
};

export default StarRating;
