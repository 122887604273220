import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { addFeedback } from '../services/feedbackService';
import StarRating from '../services/StarRating';
import { videoData_Grille } from '../videos/videosData.js';
import { Padding } from '@mui/icons-material';
import { colors } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';


import styled from '@emotion/styled';

const Title = styled.h1`
  color: #EA6666;

  @media (max-width: 56.25em) {
    font-size: 1.5em;
    padding: 3px;
  }
`;

const VideoGrid = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;

  @media (max-width: 56.25em) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const VideoTitle = styled.p`
  color: #EA6666;
  margin-top: 10px;
  text-align: center;
  margin-left: 7.5%;
  margin-right: 7.5%;

  @media (max-width: 56.25em) {
    margin-top: 1px;
    font-size: 0.9em;
  }
`;

const Introduction = styled.p`
    color: white;
    margin-top: 5px;

  @media (max-width: 56.25em) {
    margin-top: 1px;
    font-size: 0.9em;
    padding: 3px;
  }
`;

const SelectedThumbnailContainer = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: 56.25em) {
    width: 17.5%;
  }
`;

const SelectedThumbnail = styled.img`
  width: 125px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 56.25em) {
    width: 100%;
  }
`;

const ConfirmButton = styled.button`
  padding: 10px 20px;
  background-color: #EA6666;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: 56.25em) {
    padding: 3px 5px;
  }
`;

const SelectedCounter = styled.div`
  color: #EA6666;
  font-size: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 56.25em) {
    font-size: 1em;
  }
`;

const UpButton = styled.button`
    position: fixed;
    bottom: 4%;
    right: 1%;
    font-size: 15px;
    background-color: rgba(234, 102, 102, 0.9);
    border: none;
    color: white;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;

    @media (max-width: 56.25em) {
    font-size: 10px;
    right: 3%;
    bottom: 8%;
  }
`;


const Grille: React.FC = () => {
  // Feedback 
  const [rating, setRating] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [ip, setIp] = useState<string>('');

  useEffect(() => {
    // Fetch public IP address
    axios.get('https://api.ipify.org?format=json')
        .then(response => {
            setIp(response.data.ip);
        })
        .catch(error => {
            console.error('Failed to fetch IP address:', error);
        });
}, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Empêche le rafraîchissement de la page
    addFeedback("grille", rating, ip);
    setRating(0);
    setSubmitted(true);
  };
  //End

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [shuffledVideos, setShuffledVideos] = useState(() => shuffleArray([...videoData_Grille]));

  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);
  const navigate = useNavigate();

  const handleVideoClick = (id: number) => {
    setSelectedVideos((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(videoId => videoId !== id);
      } else if (prevSelected.length < 4) {
        return [...prevSelected, id];
      }
      return prevSelected;
    });
  };

  const handleSelectedVideoClick = (id: number) => {
    setSelectedVideos((prevSelected) => prevSelected.filter(videoId => videoId !== id));
  };

  const enterFullScreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
        element.requestFullscreen().catch(err => console.log(err));
    } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen().catch(err => console.log(err));
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen().catch(err => console.log(err));
    } else {
        console.log("Fullscreen API is not supported.");
    }

    // Forcing full screen on iOS devices
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector("meta[name=viewport]") as HTMLMetaElement;
      if (viewportMeta) {
          viewportMeta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      }
      window.scrollTo(0, 1);
    }
  };

  const navigateToPage3 = () => {
      if (selectedVideos.length > 1) {
        enterFullScreen();
        const selectedPaths = selectedVideos.map(id => shuffledVideos.find(video => video.id === id)?.src);
        navigate('/Grille_video', { state: { paths: selectedPaths } });
      }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={styles.homepage}>
      <h2>Feedback de la feature</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <StarRating rating={rating} setRating={setRating} />
          </label>
        </div>
        <button style={styles.submitButton} type="submit">Envoyer</button>
      </form>
      {submitted && <p>Merci pour vos feedbacks ! Si vous avez 1min afin de répondre anonyment à un formulaire de feedback, c'est par <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2p0vmB-SBnrl4vpniO9Nj-IefFVWSWvrO7q4QmIb3krR1HQ/viewform" style={styles.link} target="_blank"
      rel="noopener noreferrer">ici</a></p>}
      <Title>Composition de la grille</Title>
      <Introduction>Veuillez choisir jusqu'à 4 vidéos parmis notre catalogue et confirmez votre sélection</Introduction>
      <VideoGrid>
        {shuffledVideos.map((video) => (
          <VideoPreview
            key={video.id}
            video={video}
            onVideoClick={() => handleVideoClick(video.id)}
            isSelected={selectedVideos.includes(video.id)}
          />
        ))}
      </VideoGrid>
      <div style={styles.selectedVideosBar}>
        {selectedVideos.length > 0 && (
          <SelectedCounter>
            {selectedVideos.length}/4
          </SelectedCounter>
        )}
        {selectedVideos.map(id => {
          const video = shuffledVideos.find(v => v.id === id);
          return (
            <SelectedThumbnailContainer key={id}>
              <SelectedThumbnail
                src={`/thumbnails/grille/${id}.jpg`}
                alt={video?.title}
                onClick={() => handleSelectedVideoClick(id)}
              />
              <div style={styles.removeIcon} onClick={() => handleSelectedVideoClick(id)}>✖</div>
            </SelectedThumbnailContainer>
          );
        })}
        {selectedVideos.length > 1 && (
          <ConfirmButton  onClick={navigateToPage3}>
            Confirm Selection
          </ConfirmButton>
        )}
      </div>
      <UpButton onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </UpButton>
    </div>
  );
};

const VideoPreview: React.FC<{ video: { id: number; src: string; title: string; previews: string[] }; onVideoClick: () => void; isSelected: boolean }> = ({ video, onVideoClick, isSelected }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  const videoPreviewRef = useRef<HTMLDivElement | null>(null);
  const isMobile = window.matchMedia('(max-width: 56.25em)').matches;

  const handleMouseEnter = () => {
    if (!isMobile) {
      hoverTimeoutRef.current = setTimeout(() => {
        setIsHovered(true);
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(false);
    setPreviewIndex(0);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isHovered) {
      interval = setInterval(() => {
        setPreviewIndex((prevIndex) => (prevIndex + 1) % video.previews.length);
      }, 1000); // Changer d'image toutes les secondes
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isHovered, video.previews.length]);

  useEffect(() => {
    if (isMobile && videoPreviewRef.current) {
      intersectionObserverRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.7) {
              hoverTimeoutRef.current = setTimeout(() => {
                setIsHovered(true);
              }, 3000); // 3 secondes de délai
            } else {
              if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current);
              }
              setIsHovered(false);
              setPreviewIndex(0);
            }
          });
        },
        {
          threshold: [0.7],
        }
      );

      intersectionObserverRef.current.observe(videoPreviewRef.current);

      return () => {
        if (intersectionObserverRef.current) {
          intersectionObserverRef.current.disconnect();
        }
      };
    }
  }, [isMobile]);

  return (
    <div
      ref={videoPreviewRef}
      style={{
        ...styles.videoPreview,
        border: isSelected ? '2px solid #EA6666' : 'none',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onVideoClick}
    >
      <img
        src={isHovered ? video.previews[previewIndex] : `/thumbnails/grille/${video.id}.jpg`}
        alt={video.title}
        style={styles.thumbnail}
      />
      <VideoTitle>{video.title}</VideoTitle>
    </div>
  );
};

const styles = {
  homepage: {
    textAlign: 'center' as const,
    backgroundColor: '#202020',
    color: 'white',
  },
  loading: {
    color: '#EA6666',
    fontSize: '20px',
  },
  videoPreview: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
  },
  thumbnail: {
    width: '85%',
    height: 'auto',
    borderRadius: '8px',
  },
  videoTitle: {
    color: '#EA6666',
    marginTop: '10px',
    textAlign: 'left' as const, // Align text to the left
    width: '100%', // Set the width to match the thumbnail
  },
  selectedVideosBar: {
    position: 'fixed' as const,
    bottom: 0,
    width: '100%',
    backgroundColor: '#202020',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
  },
  removeIcon: {
    position: 'absolute' as const,
    top: '0px',
    right: '0px',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    width: '15px',
    height: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#EA6666',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '5px',
  },
  link: {
    color: '#3D40FF',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
};

export default Grille;
